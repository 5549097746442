<template>
    <v-container fluid>
      <!---BOC:[breadcrumb]-->
      <ABreadcrumb :items="breadcrumbTabulator.items" />
      <!--EOC-->
      <BreadHeader
        :title="`New ${
          childModel.name[parentModel.key]
            ? childModel.name[parentModel.key].singular
            : childModel.name.singular
        }`"
      />
      <!---BOC:[form]-->
      <BreadTabulatorForm
        :isLoading="apiReadParent.isLoading"
        ref="form"
        action="add"
        :api="api"
        :model="childModel"
        :parentModel="parentModel"
      />
      <!--EOC-->
    </v-container>
  </template>
  
  <script>
  //BOC:[api]
  import Api from "@/objects/api";
  //EOC
  //BOC:[breadcrumb]
  import BreadcrumbTabulator from "@/objects/breadcrumbTabulator";
  //EOC
  //BOC:[model]
  import Model from "@/objects/model";
  //EOC
  //BOC:[service]
  import Service from "@/objects/service";
  //EOC
  import { mapState } from "vuex";
  export default {
    components: {
      //
    },
    computed: mapState({
      services: (state) => state.service.data.services,
    }),
    props: {
      //
    },
    data: () => ({
      //BOC:[api]
      api: new Api(),
      //EOC
      //BOC:[apiReadParent]
      apiReadParent: new Api(),
      //EOC
      //BOC:[breadcrumbs]
      breadcrumbTabulator: new BreadcrumbTabulator(),
      //EOC
      //BOC:[childModel]
      childModel: new Model(),
      //EOC
      //BOC:[parentModel]
      parentModel: new Model(),
      parentModelId: null,
      //EOC
      //BOC:[service]
      service: new Service(),
      childService: new Service(),
      //EOC
      apiStoreUrl: "",
    }),
    created() {
      //BOC:[parentModel]
      this.parentModel.getByKey(this.$route.params.parentModelKey);
      this.parentModelId = this.$route.params.parentModelId;
      //EOC
      //BOC:[childModel]
      this.childModel.getByKey(this.$route.params.childModelKey);
      //EOC
      //BOC:[service]
      if (this.parentModel.serviceKey)
        this.service.set("key", this.parentModel.serviceKey);
      //EOC

      if (this.childModel.serviceKey)
        this.childService.set("key", this.childModel.serviceKey);

      //BOC:[breadcrumbs]
      this.breadcrumbTabulator.addForOperationChildAdd({
        services: this.services,
        parentModel: this.parentModel,
        parentModelId: this.parentModelId,
        childModel: this.childModel,
      });
      //EOC
      //BOC:[apiReadParent]
      this.apiReadParent.setUrl(
        `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${
          this.parentModel.key
        }/${this.parentModelId}/read`
      );
      this.apiReadParent.setCallbackCompleted((resp) => {
        var data = {
          [this.$_ucfirst(this.parentModel.key)]:
            resp
        };
        this.$refs.form.setData(data);
      });
      this.apiReadParent.fetch();
      //EOC
      //BOC:[api]

      if(this.childModel.apiUrls.apiStoreUrl != ''){
        this.apiStoreUrl = `${this.$service[this.childService.key]}${this.childModel.apiUrls.apiStoreUrl}`;
      }else{
        this.apiStoreUrl = `${this.$service[this.childService.key]}/v1/en/console/tabulator/model/${
          this.childModel.key
        }/add`;
      }

      this.api.setMethod(`POST`);
      this.api.setUrl(this.apiStoreUrl);
      //
     
      //
      this.api.setCallbackCompleted(() => {
        this.$router.push({
          name: "PageServiceModelBreadTabulatorRead",
          query: {
            tab: this.childModel.key,
          },
          params: {
            serviceKey: this.parentModel.serviceKey,
            modelKey: this.parentModel.key,
            modelId: this.parentModelId,
          },
        });
        this.$store.dispatch("showMessage", {
                    message: "Created successfully.",
                    messageType: "success",
                    timeout:2000
                });
      });
      //EOC

      
    },
    mounted() {
      //
    },
    methods: {
      //
    },
  };
  </script>